
/* General */
body {
    font-family: 'Raleway', sans-serif;
}

html {
    scroll-behavior: smooth;
}

:root {
    --nasmo-red: #E30613;
}

.mwa-color {
    color: var(--nasmo-red);
}

.mwa-bg {
    background-color: var(--nasmo-red);
}

a {
    text-decoration: none!important;
}

.slick-next::before, .slick-prev::before{
    color :#000000!important;
}

.nasmo-shadow {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
}

p {
    font-size: 1.1rem;
}

h1 {
    margin-bottom: 0;
}

.bg-dark {
    height: 250px;
}

 h2 {
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}

h2 span {
        border-bottom-color: var(--nasmo-red);
    padding-bottom: 5px;
    border-bottom-style: solid;

}

h3 {
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
}

h4 {
    font-size: 1.8rem;
    letter-spacing: 1px;
    font-weight: 800;
}

button:focus {
    outline: 0;
}
@media screen and (max-width: 1200px) {
    .overflow-body {
        overflow:hidden;
    }
}


/* Navbar  */

.navbar {
    position: fixed!important;
    top: 0;
    left: 0;
}

.logo {
    width: 214px;
}

nav {
    height: 90px;
    text-transform: uppercase;
    width: 100%;
    z-index: 6;
    position:absolute!important;
    top:0;
}

.hvr-underline-from-center {
    padding-bottom: 3px;
    text-decoration: none;
    color: #000000 !important;
}

@media (min-width:1200px) {
    .nav-link {
        opacity: 1!important;
    }
}

  @keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* separator */


.separator {
    background: url('/img/red-separator.svg');
    background-repeat: repeat-x;
    height: 35px;
    background-size: auto 100%;
    background-position: center;
}

.white-separator {
    background: url('/img/white-separator.svg') var(--nasmo-red);
    background-repeat: repeat-x;
    background-position: center;
    background-size: auto 100%;
    height: 35px;
}


/* Métiers */

.tipis-bg {
    background-image: url('/img/bg.png');
    background-repeat: repeat-x;
    background-position: bottom;
}

.tipis-bg-reverse {
    background-image: url('/img/bg-reverse.png');
    background-repeat: repeat-x;
    background-position: top;
    background-size:contain;
}

.metier {
    display:flex;
}

.metier, .metier .flip-card-back, .metier .flip-card-front {
    height: 270px;
    width: 100%;
}

.metier img {
    max-height: 50px;
    max-width: 50px;
}

.metier p {
    font-size: 1em;
}

.metier .more {
    font-size: .8rem;
    color: #999999;
    cursor: pointer;
    font-style: italic;
}

.metiers-carousel .slick-slide {
    max-height: 250px;
    margin: 0 27px;

}

.metiers-carousel img {
    max-width: 100%;
    cursor: pointer;
}

.metiers-carousel .slick-list {
    margin: 0 -27px;
}

    .flip-card {
        cursor: pointer;
      background-color: transparent;
      perspective: 1000px; 
      color: #000000!important;
    }
    
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }
    
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }
    
    .flip-card-front, .flip-card-back {
      position: absolute;
      -webkit-backface-visibility: hidden; 
      backface-visibility: hidden;
    }
    
    .flip-card-back {
        background-color:var(--nasmo-red);
        color:white!important;
      transform: rotateY(180deg);
    }

/* Chapo */


#chapo .p {
    font-size: 1.1rem;
}

.corner{
    width:25px;
    height: 25px;
}

.r133 {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
}

.r45 {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.r90 {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.r180 {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.r270 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}


/* Références */


#references img {
    width: 100%;
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
}

.white-wrapper {
    background: #FFFFFF;
}


.slick-track {
    display: flex;
}

.slick-track .slick-slide {
    display: flex;
    padding: 10px;
    height: auto;
    align-items: center;
    justify-content: center;
}

.team-modal .photo {
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
}

.slick-list.draggable {
    overflow: hidden;
}

.slick-prev:before, .slick-next:before {
    color:#BFBFBF;
}

.slick-dots {
    bottom:-35px;
}

.references-carousel {
    position: relative;
}

/* Contact */
#contact {
    color:white;
    background-color: var(--nasmo-red);
}
#contact a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.4rem;
}

.map {
    width: 100%;
    min-height: 400px;
}

.contact-sep {
    border-bottom: solid 1px;
    width: 50px;
    align-self: center;
    margin:16px;
}

.white-underline {
    border-bottom:solid #FFFFFF;
    padding-bottom: 5px;
}

/* Footer */

footer {
    height: 65px;
    color: var(--nasmo-red);
    padding:0.5rem 1rem;
}

footer img {
    width: 40px;
    height: 40px;
}

/* Underline From Center */
.hvr-underline-from-center, .underlined {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

.hvr-underline-from-center:before, .underlined:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: var(--nasmo-red);
    height: 2px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
    left: 8px;
    right: 8px;
}

.underlined::before {
    left: 8px;
    right: 8px;
} 


#realisations .hvr-underline-from-center:hover:before,
#realisations .hvr-underline-from-center:focus:before,
#realisations .hvr-underline-from-center:active:before {
    left: 0px;
    right: 0px;
}

#realisations .underlined::before {
    left: 0px;
    right: 0px;
} 


#realisations .vertical-real {
display: none;
}

@media (min-width:1300px) {

    #realisations  .vertical-real {
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        top: 39%;
        left: -180px;
        display:block;
        width: 265px;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
    }
    
    #realisations  .vertical-real hr {
        border-top: 2px solid #E30613;
    }
}

// Page Métiers

  /* Quotes */
.quotes {
    position: absolute;
    width: 50px;
}

.quotes.left {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    left: -10px;
    top: 0;
}

.quotes.right {
    right: -10px;
    bottom: 30px;
}


.real-menu {
    flex-wrap: wrap;
    justify-content: center;
}

.real-menu a {
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
}

/* Header video */
.vid_1 video,
.vid_2 video {
    object-fit: cover;
    width: 100%;
}

.vid_1 {
    display: flex;
    align-items: flex-end;
    background: var(--nasmo-red);
}

.vid_2 {
    display: flex;
    align-items: flex-end;
}

.py-7 {
    padding: 7rem 0rem;
}


    @media (min-width: 1200px) {
        .py-xl-7 {
        padding: 7rem 0rem!important;
        }
    }

.mt-7 {
    margin-top: 7rem;
}

//navbar 




.mt-90{
    margin-top:90px;
}

@media screen and (max-width: 1200px) {
    .m-open #mwa-navbar {
        text-align: center;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: fixed;
        width: 100vw;
        height: 100vh;
        font-size: 1.5rem;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .bg-mobile-menu {
    width: 0vw;
    transition: all .4s ease;
    height: 100vh;
    left:0;
    top:0;
    position: fixed;
    background: #FFFFFF;
    z-index: 5;
}

    .m-open #mwa-navbar .navbar-nav {
        margin-top:70px;
    }
    

    .m-open .navbar-brand,
    .m-open .hamburger {
        z-index: 3;
    }

    .m-open #mwa-navbar .nav-item:not(:first-child):before {
        background-image: url('/img/losange.svg');
        background-size: 20px;
        display: block;
        width: 20px;
        height: 20px;
        margin: 2vh auto;
        content: "";
    }

}


// Tribu 


.members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.members .title {
    margin-top: 5px;
}


.member {
    flex-basis: 50%;
    padding: 15px;
}


@media screen and (min-width: 768px) {
    .member {
        padding: 0;
    }
    /* Grow Rotate */
    .hvr-grow-rotate {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        cursor: pointer;
    }

    .hvr-grow-rotate:hover,
    .hvr-grow-rotate:focus,
    .hvr-grow-rotate:active {
        -webkit-transform: scale(1.1) rotate(4deg);
        transform: scale(1.1) rotate(4deg);
    }

    .members {
        height: 280px;
        width: 100%;
        margin: 200px 0;
        position: relative;
        background-image: url('/img/team/bg.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    .members .title {
        width: 180px;
        position: absolute;
        transform: translate(-50%, -50%);
    }


    .member {
        position: absolute;
        width: 180px;
        height: 180px;
        transform: translate(-50%, -50%);
    }


    /* PLACER LES TEXTES DES IMAGES  */
    /* Avelaine */
    .member:nth-child(1) .title {
        top: -35px;
        left: 50%;
    }

    /* Greg */
    .member:nth-child(2) .title {
        top: -45px;
        left: 50%;
    }

    /* Sana */
    .member:nth-child(3) .title {
        top: -35px;
        left: 50%;
    }

    /* Brice */
    .member:nth-child(4) .title {
        bottom: -105px;
        left: 25%;
    }

    /* fanny */
    .member:nth-child(5) .title {
        left: 50%;
        bottom: -70px;
    }

    /* fred */
    .member:nth-child(6) .title {
        left: 50%;
        bottom: -70px;
    }

    /* guillaume */
    .member:nth-child(7) .title {
        left: 50%;
        bottom: -105px;
    }

    /* Amélie */
    .member:nth-child(8) .title {
        right: -115px;
        bottom: -105px;
    }

    /* PLACER LES IMAGES */

    /* Avelaine */
    .member:nth-child(1) {
        right: 11%;
        top: 8%;
        width: 122px;
        height: 122px;
    }

    /* Greg */
    .member:nth-child(2) {
        left: 50%;
        top: -15px;
        width: 120px;
        height: 120px;
    }

    /* Sana */
    .member:nth-child(3) {
        left: 25%;
        top: 8%;
        width: 122px;
        height: 122px;
    }

    /* Brice */
    .member:nth-child(4) {
        left: 10%;
        top: 50%;
        width: 124px;
        height: 124px;
    }

    /* fanny */
    .member:nth-child(5) {
        left: 25%;
        top: 91%;
        width: 138px;
        height: 138px;
    }

    /* fred */
    .member:nth-child(6) {
        left: 50%;
        top: 100%;
        width: 154px;
        height: 154px;
    }

    /* guillaume */
    .member:nth-child(7) {
        left: 75%;
        top: 91%;
        width: 138px;
        height: 138px;
    }

    /* marie */
    .member:nth-child(8) {
        left: 90%;
        top: 50%;
        width: 124px;
        height: 124px;
    }

}

@media screen and (min-width: 992px) {


    .members {
        height: 380px;
    }

    /* PLACER LES TEXTES DES IMAGES  */
    /* Avelaine */
    .member:nth-child(1) .title {
        top: -35px;
        left: 50%;
    }

    /* Greg */
    .member:nth-child(2) .title {
        top: -45px;
        left: 50%;
    }

    /* Sana */
    .member:nth-child(3) .title {
        top: -35px;
        left: 50%;
    }

    /* Brice */
    .member:nth-child(4) .title {
        bottom: -105px;
        left: 25%;
    }

    /* fanny */
    .member:nth-child(5) .title {
        left: 50%;
        bottom: -70px;
    }

    /* fred */
    .member:nth-child(6) .title {
        left: 50%;
        bottom: -70px;
    }

    /* guillaume */
    .member:nth-child(7) .title {
        left: 50%;
        bottom: -105px;
    }

    /* amelie */
    .member:nth-child(8) .title {
        right: -115px;
        bottom: -105px;
    }

    /* PLACER LES IMAGES */

    /* Avelaine */
    .member:nth-child(1) {
        right: 11%;
        top: 8%;
        width: 142px;
        height: 142px;
    }

    /* Greg */
    .member:nth-child(2) {
        left: 50%;
        top: -15px;
        width: 140px;
        height: 140px;
    }

    /* Sana */
    .member:nth-child(3) {
        left: 25%;
        top: 8%;
        width: 142px;
        height: 142px;
    }

    /* Brice */
    .member:nth-child(4) {
        left: 10%;
        top: 50%;
        width: 144px;
        height: 144px;
    }

    /* fanny */
    .member:nth-child(5) {
        left: 25%;
        top: 91%;
        width: 158px;
        height: 158px;
    }

    /* fred */
    .member:nth-child(6) {
        left: 50%;
        top: 100%;
        width: 174px;
        height: 174px;
    }

    /* guillaume */
    .member:nth-child(7) {
        left: 75%;
        top: 91%;
        width: 158px;
        height: 158px;
    }

    /* marie */
    .member:nth-child(8) {
        left: 90%;
        top: 50%;
        width: 144px;
        height: 144px;
    }
}

@media screen and (min-width: 1200px) {
    .members {
        height: 480px;
    }

    /* PLACER LES TEXTES DES IMAGES  */
    /* Avelaine */
    .member:nth-child(1) .title {
        top: -35px;
        left: 50%;
    }

    /* Greg */
    .member:nth-child(2) .title {
        top: -45px;
        left: 50%;
    }

    /* Sana */
    .member:nth-child(3) .title {
        top: -35px;
        left: 50%;
    }

    /* Brice */
    .member:nth-child(4) .title {
        bottom: -105px;
        left: 25%;
    }

    /* fanny */
    .member:nth-child(5) .title {
        left: 50%;
        bottom: -70px;
    }

    /* fred */
    .member:nth-child(6) .title {
        left: 50%;
        bottom: -70px;
    }

    /* guillaume */
    .member:nth-child(7) .title {
        left: 50%;
        bottom: -105px;
    }

    /* amelie */
    .member:nth-child(8) .title {
        right: -115px;
        bottom: -120px;
    }

    /* PLACER LES IMAGES */

    /* Avelaine */
    .member:nth-child(1) {
        right: 11%;
        top: 8%;
        width: 160px;
        height: 160px;
    }

    /* Greg */
    .member:nth-child(2) {
        left: 50%;
        top: 0;
        width: 150px;
        height: 150px;
    }

    /* Sana */
    .member:nth-child(3) {
        left: 25%;
        top: 8%;
        width: 160px;
        height: 160px;
    }

    /* Brice */
    .member:nth-child(4) {
        left: 9%;
        top: 50%;
        width: 170px;
        height: 170px;
    }

    /* fanny */
    .member:nth-child(5) {
        left: 25%;
        top: 92%;
        width: 184px;
        height: 184px;
    }

    /* fred */
    .member:nth-child(6) {
        left: 50%;
        top: 100%;
        width: 200px;
        height: 200px;
    }

    /* guillaume */
    .member:nth-child(7) {
        left: 75%;
        top: 92%;
        width: 184px;
        height: 184px;
    }

    /* amelie */
    .member:nth-child(8) {
        left: 91%;
        top: 50%;
        width: 180px;
        height: 170px;
    }
}

// Modal tribu



.team-modal h2 {
    font-size: 1.2rem;
    text-align: left;
    letter-spacing: 0;
    text-decoration: none;
}

.team-modal h3 {
    font-size: 2rem;
    text-align: left;
    letter-spacing: 0;
    text-decoration: none;
}

.team-modal h5 {
    text-align: left;
    font-weight: bold;
}

.team-modal p {
    text-align: left;
    font-size:1.1rem;
    line-height: 1.4;
    margin:0;
}

.swal2-content {
    padding: 0!important;
}